import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseQuery } from "../utils";

export const threadsApi = createApi({
  reducerPath: "threadsApi",
  baseQuery: fetchBaseQuery(baseQuery),
  tagTypes: ["Threads"],
  endpoints: (builder) => ({
    getAssistantThreads: builder.query({
      query: ({ assistantId, page, pageSize, sortOrder = 'desc', orderByField = 'created_at', startDate, endDate  }) =>{
        const params = new URLSearchParams();
        params.append('sort', sortOrder);
        params.append('orderBy', orderByField);

        if (startDate) params.append('start', startDate);
        if (endDate) params.append('end', endDate);
        return {
          url:  `/assistants/${assistantId}/threads?page=${page}&limit=${pageSize}`,
          params,
        };
      }
       ,
      providesTags: ["Threads"],
    }),
    getAllAssistantThreads: builder.query({
      query: ({ assistantId, page, pageSize, sortOrder = 'desc', orderByField = 'created_at', startDate, endDate }) =>{
        const params = new URLSearchParams();
        params.append('sort', sortOrder);
        params.append('orderBy', orderByField);

        if (startDate) params.append('start', startDate);
        if (endDate) params.append('end', endDate);
        return {
          url:  `/assistants/${assistantId}/threads?page=${page}&limit=${pageSize}`,
          params,
        };
      },
      providesTags: ["Threads"],
    }),
    getAssistantThreadById: builder.query({
      query: ({ assistantId, threadId }) =>
        `/assistants/${assistantId}/threads/${threadId}`,
    }),
    getThreadCountByDay: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/threads-usage-by-day`,
          params,
        };
      },
    }),
    getThreadCountByHour: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/threads-usage-by-hour`,
          params,
        };
      },
    }),
    getThreadCountByDate: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/threads-count-by-date`,
          params,
        };
      },
    }),
    getUniqueUsers: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/unique-users-threads-count`,
          params,
        };
      },
    }),
    getTokenUsage: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/total-token-usage`,
          params,
        };
      },
    }),
    getThreadsDuration: builder.query({
      query: ({ assistant }) => `/assistants/${assistant}/threads-duration`,
    }),
    getThreadsUserPersonas: builder.query({
      query: ({ assistant }) =>
        `/assistants/${assistant}/threads-user-personas-count`,
    }),
    getThreadsTokenUsage: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/threads-token-usage`,
          params,
        };
      },
    }),
    getTokenUsageByHour: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/tokens-usage-by-hour`,
          params,
        };
      },
    }),
    getTokenUsageByDay: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/tokens-usage-by-day`,
          params,
        };
      },
    }),
    getThreadsMessageCountDistribution: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/threads-message-count-distribution`,
          params,
        };
      },
    }),
    getThreadsOverTime: builder.query({
      query: ({ assistant, startDate, endDate }) => {
        const params = new URLSearchParams();
        if (startDate) params.append("start", startDate);
        if (endDate) params.append("end", endDate);
        return {
          url: `/assistants/${assistant}/threads-over-time`,
          params,
        };
      },
    }),
  }),
});

export const {
  useGetAssistantThreadsQuery,
  useLazyGetAllAssistantThreadsQuery,
  // useGetAllAssistantThreadsQuery,
  useGetAssistantThreadByIdQuery,
  useGetThreadCountByDayQuery,
  useGetThreadCountByHourQuery,
  useGetThreadCountByDateQuery,
  useGetUniqueUsersQuery,
  useGetTokenUsageQuery,
  useGetThreadsDurationQuery,
  useGetThreadsUserPersonasQuery,
  useGetThreadsTokenUsageQuery,
  useGetTokenUsageByHourQuery,
  useGetTokenUsageByDayQuery,
  useGetThreadsMessageCountDistributionQuery,
  useGetThreadsOverTimeQuery,
} = threadsApi;
