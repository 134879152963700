import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Button,
  useToast,
  Flex,
  Select,
  Card,
  useColorModeValue,
  Text,
  Checkbox,
} from "@chakra-ui/react";
import { BsEyeFill } from "react-icons/bs";
import { format } from "date-fns";
import { useGetAssistantThreadsQuery } from "store/api/threads";
import CustomTable from "components/tables/CustomTable";
import Loader from "components/loader/Loader";
import { useSelector } from "react-redux";
import { convertToCSV } from "utils/convertToCSV";
import { useNavigate } from "react-router-dom";
import Calendar from "components/calendar";
import { useLazyGetAllAssistantThreadsQuery } from "store/api/threads";

const Threads = () => {
  const selectedAssistant = useSelector(
    (state) => state.admin.selectedAssistant
  );
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const cardBg = useColorModeValue("white", "#323232");
  const OptionBg = useColorModeValue("white", "#434343");
  const [orderType, setOrderType] = useState("desc");
  const [orderBy, setOrderBy] = useState("created_at");
  const [disable, setDisable] = useState(true);
  const defaultFilterDates = { all: [null, null] };
  const [isChecked, setIsChecked] = useState(true);
  const pageSize = 30;
  const [filterDates, setFilterDates] = useState(defaultFilterDates);
  const [queryParams, setQueryParams] = useState({
    orderByField: "created_at",
    sortOrder: "desc",
    startDate: filterDates.all[0],
    endDate: filterDates.all[1],
  });
  const [tempParams, setTempParams] = useState(queryParams);
  const [trigger] = useLazyGetAllAssistantThreadsQuery();
  const { data, isLoading, isFetching } = useGetAssistantThreadsQuery(
    {
      assistantId: selectedAssistant?.id,
      page,
      pageSize,
      ...queryParams,
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const fetchAllThreads = async () => {
    if (data.threads) {
      const totalPages = Math.ceil(data.totalItems / pageSize);
      const allThreads = [];
      try {
        const promises = [];
        for (let i = 1; i <= totalPages; i++) {
          promises.push(
            trigger(
              {
                assistantId: selectedAssistant?.id,
                page: i,
                pageSize,
                ...queryParams,
              },
              {
                skip: !selectedAssistant?.id,
              }
            ).unwrap()
          );
        }
        const results = await Promise.all(promises);
        results.forEach((result) => {
          allThreads.push(...result.threads);
        });
        return allThreads;
      } catch (error) {
        console.error("Error fetching threads:", error);
        toast({
          title: "An error occurred while fetching threads",
          description: error.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const dates = tableData?.map((date) => date?.created_at);
  const lowestDate = new Date(
    Math.min(...dates?.map((date) => new Date(date)))
  );
  const highestDate = new Date().toISOString().split("T")[0];

  const safeFormat = (date) => {
    if (!date || isNaN(new Date(date).getTime())) {
      return "no date";
    }
    return format(new Date(date), "dd-MM-yyyy");
  };

  const handleViewThread = (id) => {
    navigate(`/conversation/${id}`);
  };

  const handleExport = async () => {
    setLoading(true);
    try {
      const allThreads = await fetchAllThreads();

      if (allThreads && allThreads.length > 0) {
        convertToCSV(allThreads, selectedAssistant?.name, [
          "deleted",
          "request_reference",
          "metadata_fetched",
        ]);
      } else {
        throw new Error("Failed to fetch threads: Data is not an array");
      }
    } catch (error) {
      toast({
        title: error.message || "Failed to fetch threads",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "Message",
      accessor: "first_message",
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ value }) => <> {format(new Date(value), "dd-MM-yyyy")}</>,
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Time of Use",
      accessor: "time_of_use",
    },
    {
      Header: "Token",
      accessor: "total_tokens",
    },
    {
      Header: "User Persona",
      accessor: "user_persona",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <Box onClick={() => handleViewThread(row.original?.id)} ml={5}>
          <BsEyeFill size={20} cursor="pointer" />
        </Box>
      ),
    },
  ];
  const handleApplySort = () => {
    setIsChecked(false);
    setQueryParams({
      ...tempParams,
      startDate: filterDates.all[0],
      endDate: filterDates.all[1], 
    });
    if (filterDates.all[0] && filterDates.all[1]) {
      setPage(1);
    }
    if (filterDates.all[0] === null && filterDates.all[1] === null) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
    setDisable(false);
  };

  const handleCancelDate = () => {
    setOrderType("desc");
    setOrderBy("created_at");
    setFilterDates(defaultFilterDates);
    setIsChecked(true);
    setDisable(true);
    // Reset queryParams to their default values
    setQueryParams({
      orderByField: "created_at",
      sortOrder: "desc",
      startDate: defaultFilterDates.all[0],
      endDate: defaultFilterDates.all[0],
    });
  };

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked); 
    if (!isChecked) {
      setFilterDates({ all: [null, null] }); 
    }
    setDisable(!isChecked);
  
    setQueryParams({
      ...tempParams,
      startDate: isChecked ? defaultFilterDates.all[0] : null,
      endDate: isChecked ? defaultFilterDates.all[1] : null,
    });
  };
  useEffect(() => {
    if (data) {
      setTableData(data.threads ?? []);
      setTotalItems(data.totalItems ?? 0);
    }
  }, [data]);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "10px", xl: "10px" }}
        justifyItems="end"
      >
        <Button
          variant="brand"
          fontWeight="500"
          onClick={handleExport}
          isDisabled={loading}
          isLoading={loading}
          width="max-content"
          minW={100}
        >
          Export
        </Button>
        <Card
          w="100%"
          p={4}
          borderRadius={15}
          bg={cardBg}
          flexWrap="wrap"
          justifyContent="space-between"
          flexDirection="row"
        >
          <Flex flexWrap="wrap" gap={2} alignItems="center">
            <Text>Sort By</Text>
            <Select
              width="max-content"
              borderRadius={15}
              _focus={{
                borderColor: "#E56B20",
                boxShadow: "0 0 0 1px #E56B20",
              }}
              onChange={(e) =>
                setTempParams({ ...tempParams, orderByField: e.target.value })
              }
              minW={100}
            >
              <option value="created_at" style={{ background: OptionBg }}>
                Date
              </option>
              <option value="tokens" style={{ background: OptionBg }}>
                Token
              </option>
              <option value="user_persona" style={{ background: OptionBg }}>
                User Persona
              </option>
            </Select>
            <Text>Order By</Text>
            <Select
              width="max-content"
              minW={100}
              borderRadius={15}
              _focus={{
                borderColor: "#E56B20",
                boxShadow: "0 0 0 1px #E56B20",
              }}
              onChange={(e) =>
                setTempParams({ ...tempParams, sortOrder: e.target.value })
              }
              defaultValue="desc"
            >
              <option value="desc" style={{ background: OptionBg }}>
                Desending order
              </option>
              <option value="asc" style={{ background: OptionBg }}>
                Asending order
              </option>
            </Select>
          </Flex>

          <Flex
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap="wrap"
            gap={3}
          >
            <Checkbox
              pr={3}
              borderRight="1px solid #c7c7c7"
              isChecked={isChecked}
              onChange={handleCheckboxChange}
            >
              All Records
            </Checkbox>
            <Text>Select a Date Range</Text>
            <Calendar
              selectRange={true}
              setFilterDates={setFilterDates}
              filterKey={"all"}
              iconSize={5}
            />
            <Flex gap={2}>
              {filterDates.all[0] === null && filterDates.all[1] === null ? (
                <Text color="#c7c7c7" fontSize={14} fontWeight={600}>
                  from {safeFormat(lowestDate)} to{" "}
                  {format(highestDate, "dd-MM-yyyy")}
                </Text>
              ) : (
                <>
                  <Text fontWeight={600}>
                    {format(filterDates.all[0], "dd-MM-yyyy")}
                  </Text>
                  <Text fontWeight={600}>to</Text>
                  <Text fontWeight={600}>
                    {format(filterDates.all[1], "dd-MM-yyyy")}
                  </Text>
                </>
              )}
            </Flex>
            <Button variant="outline" onClick={handleApplySort}>
              Apply
            </Button>
            <Button
              variant="outline"
              onClick={handleCancelDate}
              isDisabled={disable}
            >
              Cancel
            </Button>
          </Flex>
        </Card>

        {tableData.length === 0 && !isLoading && !isFetching ? (
          <Card
            w="100%"
            p={4}
            minHeight="250px"
            borderRadius={15}
            bg={cardBg}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Text fontSize="xl" textAlign="center" fontWeight={700}>
              No conversation available
            </Text>
          </Card>
        ) : isLoading || isFetching ? (
          <Card w="100%" p={4} minHeight="250px" borderRadius={15} bg={cardBg}>
            <Loader />
          </Card>
        ) : (
          <CustomTable
            columnsData={columns}
            tableData={tableData}
            currentPage={page}
            setCurrentPage={setPage}
            totalItems={totalItems}
            pageSize={pageSize}
            isLoading={isLoading || isFetching}
            orderBy={orderBy}
            orderType={orderType}
            setTableData={setTableData}
            selectedAssistant={selectedAssistant?.id}
          />
        )}
      </SimpleGrid>
    </Box>
  );
};

export default Threads;
