// Dashboard.jsx
import React from "react";
import { Box, SimpleGrid, useColorModeValue, Icon, UnorderedList, ListItem, Text } from "@chakra-ui/react";
import { BarCard } from "components/customCards";
import IconBox from "components/icons/IconBox";
import CustomTable from "components/tables/CustomTable";
import { useSelector } from "react-redux";
import { MdBarChart, MdPerson } from "react-icons/md";
import {
  useGetThreadCountByDayQuery,
  useGetThreadCountByHourQuery,
  useGetUniqueUsersQuery,
  useGetTokenUsageQuery,
} from "store/api/threads";
import { format } from "date-fns";
import { useGetThreadCountByDateQuery } from "store/api/threads";
import MiniStatistics from "components/card/MiniStatistics";
import AnnouncementCard from "./components/AnnouncementCard";
import { BsEyeFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useGetAssistantThreadsQuery } from "store/api/threads";
// import { getAssistantThreads } from "superbase/apis";

const Dashboard = () => {
  const brandColor = "assistantColor.500";
  const boxBg = useColorModeValue("#E56B2020", "whiteAlpha.100");
  const navigate = useNavigate();

  const tableThreadsLength = 5;
  const selectedAssistant = useSelector(
    (state) => state.admin.selectedAssistant
  );
  // console.log(selectedAssistant);
  const { data: threadsCount } = useGetAssistantThreadsQuery(
    {
      assistantId: selectedAssistant?.id,
    },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsPerHour,
    isLoading: loadingPerHour,
    isFetching: fetchingPerHour,
  } = useGetThreadCountByHourQuery(
    { assistant: selectedAssistant?.id },
    {
      skip: !selectedAssistant?.id,
    }
  );
  // const {data: threadsData} = getAssistantThreads(selectedAssistant?.id, 1, 50 )
  // console.log(threadsData)

  const {
    data: threadsPerDay,
    isLoading: loadingPerDay,
    isFetching: fetchingPerDay,
  } = useGetThreadCountByDayQuery(
    { assistant: selectedAssistant?.id },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const {
    data: threadsPerDate,
    isLoading: loadingPerDate,
    isFetching: fetchingPerDate,
  } = useGetThreadCountByDateQuery(
    { assistant: selectedAssistant?.id },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const { data: uniqueUsers } = useGetUniqueUsersQuery(
    { assistant: selectedAssistant?.id },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const { data: tokenUsage } = useGetTokenUsageQuery(
    { assistant: selectedAssistant?.id },
    {
      skip: !selectedAssistant?.id,
    }
  );

  const threadsPerDayData = threadsPerDate?.data?.reduce((obj, item) => {
    obj[item.date] = item.count;
    return obj;
  }, {});
  const handleViewThread = (id) => {
    navigate(`/conversation/${id}`);
  };

  const columns = [
    {
      Header: "Id",
      accessor: "id",
    },
    {
      Header: "Message",
      accessor: "first_message",
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ value }) => (
        <> {format(new Date(value), "dd-MM-yyyy")}</>
      ),
    },
    {
      Header: "Location",
      accessor: "location",
    },
    {
      Header: "Time of Use",
      accessor: "time_of_use",
    },
    {
      Header: "Tokens",
      accessor: "total_tokens",
    },
    {
      Header: "User Persone",
      accessor: "user_persona",
    },
    {
      Header: "Action",
      Cell: ({ row }) => (
        <Box onClick={() => handleViewThread(row.original?.id)} ml={5}>
          <BsEyeFill size={20} cursor="pointer" />
        </Box>
      ),
    },
  ];
  // console.log(selectedAssistant?.threads?.length)

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 4, "2xl": 4 }}
        gap="20px"
        mb="20px"
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Threads"
          value={threadsCount?.totalItems ?? 0}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={<Icon w="32px" h="32px" as={MdPerson} color={brandColor} />}
            />
          }
          name="Unique Users"
          value={uniqueUsers?.length ?? 0}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Token Used"
          value={tokenUsage?.totalTokens ?? 0}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="56px"
              h="56px"
              bg={boxBg}
              icon={
                <Icon w="32px" h="32px" as={MdBarChart} color={brandColor} />
              }
            />
          }
          name="Average Duration"
          value="6"
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 2, xl: 2 }}
        gap="20px"
        mb="20px"
      >
        <BarCard
          data={threadsPerHour ?? null}
          title={"Conversations Per Hour"}
          xAxisTitle="Hour of the Day"
          yAxisTitle="Total Threads"
          isLoading={loadingPerHour || fetchingPerHour}
          type="day"
          barColors={["#B83560", "#F9827A"]}
          showToolbar={false}
          details={<UnorderedList mt={3}>
              <ListItem><Text fontWeight='700'>Description:</Text> Illustrates conversations by hour. Identifies peak times for user activity.</ListItem>
              <ListItem><Text fontWeight='700'>Benefit:</Text>Adjusts staff schedules to match high activity hours for better responsiveness.</ListItem>
            </UnorderedList>}
        />
        <BarCard
          data={threadsPerDay ?? null}
          title={"Conversations Per Day"}
          xAxisTitle="Day of the Week"
          yAxisTitle="Total Threads"
          isLoading={loadingPerDay || fetchingPerDay}
          type="week"
          barColor={"#E56B20"}
          showToolbar={false}
          details={<UnorderedList mt={3}>
            <ListItem><Text fontWeight='700'>Description:</Text> Shows total conversations per day. Highlights busiest days for staffing needs.</ListItem>
            <ListItem><Text fontWeight='700'>Benefit:</Text> Ensures adequate support during peak days to improve customer service.</ListItem>
          </UnorderedList>}
        />
        <BarCard
          data={threadsPerDayData ?? null}
          title={"Conversations Per Date"}
          xAxisTitle=""
          yAxisTitle="Total Threads"
          isLoading={loadingPerDate || fetchingPerDate}
          type="date"
          barColors={["pink", "orange"]}
          showToolbar={false}
          details={<UnorderedList mt={3}>
            <ListItem><Text fontWeight='700'>Description:</Text> Displays conversations by date. Helps track daily engagement over time.</ListItem>
            <ListItem><Text fontWeight='700'>Benefit:</Text> Monitors promotional impacts and daily operational effectiveness.</ListItem>
          </UnorderedList>}
        />
        <AnnouncementCard assistantId={selectedAssistant?.id} />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 1, xl: 1 }}
        gap="20px"
        mt={5}
        mb="20px"
      >
        <CustomTable
          tableTitle="Conversation"
          columnsData={columns}
          tableData={selectedAssistant?.threads ?? []}
          hidePagination={true}
          totalItems={tableThreadsLength}
          pageSize={tableThreadsLength}
          isLoading={loadingPerDay || fetchingPerDay}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Dashboard;
