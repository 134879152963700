import { Box, Flex, Heading, Image, Text, useColorModeValue } from "@chakra-ui/react";
import Information from "components/Information/Information";
import { format } from "date-fns";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedAssistantId } from "store/slices/admin";

const Assistant = (props) => {
  const { assistant, ranking, link, image, ...rest } = props;
  const cardBg = useColorModeValue("white", "#323232");
  const dispatch = useDispatch();
  const assistantId = useSelector((state) => state.admin.selectedAssistant?.id);

  return (
    <Box
      width="70"
      bg={cardBg}
      boxShadow="0px 0px 15px rgba(0, 0, 0, 0.09)"
      p={9}
      position="relative"
      overflow="hidden"
      borderRadius={15}
      {...rest}
    >
      <Flex
        width="20"
        height="20"
        bg="linear-gradient(
          to bottom,
          rgba(237, 89, 27, 1) 0%,
          rgba(237, 89, 27, 0.5) 100%
        )"
        bgSize="cover"
        borderRadius="full"
        position="absolute"
        right="-10px"
        top="-10px"
        align="center"
        justify="center"
        display={assistant?.id === assistantId ? "none" : "flex"}
        cursor="pointer"
        onClick={() => dispatch(setSelectedAssistantId(assistant?.id))}
      >
        <Text color="white" fontSize="sm">
          Activate
        </Text>
      </Flex>
      <Image
        src={image}
        width='80px'
        style={{ borderRadius: "100%" }}
        height='80px'
        objectFit='contain'
        alt=""
      />
      <Heading as="h1" size="lg" fontWeight="bold">
        {assistant?.name}
      </Heading>
      <Text fontSize="sm" color="gray.500" lineHeight="6">
        {assistant?.description === ""
          ? "No description provided"
          : assistant?.description}
      </Text>

      <Flex justifyContent='space-between' alignItems='center' flexWrap='wrap'>
      <Information pl={0} title="Assistant ID" pr={0} value={assistant?.id} />
        <Information pl={0} 
          title="Website URL"
          value={assistant?.website_url}
          isLink={true}
        />
      </Flex>
      <Flex mt={3}>
        <Information title="Type" value={assistant?.type} />
        <Information
          title="Total Threads"
          value={assistant?.threads?.length ?? 0}
        />
        <Information
          title="Created At"
          value={format(new Date(assistant?.created_at), "dd-MM-yyyy")}
        />

        </Flex>
    </Box>
  );
};

export default Assistant;
